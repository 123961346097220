import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { flowRight, size } from 'lodash';
import {
  DISCUSSION,
  QUESTION,
} from '@wix/communities-forum-client-commons/dist/src/constants/post-types';
import { EXPERIMENT_FORUM_TABS } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';
import PostListClassicHeader from '../post-list-classic-header';
import PostListItemClassic from '../post-list-item-classic';
import CreatePostButton from '../create-post-button';
import { HorizontalSeparator, VerticalSeparator } from '../separator';
import { PaginationClassic } from '../pagination';
import { SortingSelectCategoryPageDesktop } from '../sorting-select-category';
import { FilterSelectPostTypeDesktop } from '../filter-select-post-type';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withFontClassName from '../../hoc/with-font-class-name';
import withTranslate from '../../../common/components/with-translate/with-translate';
import Loader from '@wix/communities-forum-client-commons/dist/src/components/loader';
import { getLastPage } from '@wix/communities-forum-client-commons/dist/src/services/pagination';
import { getIsViewsCountEnabled } from '../../selectors/app-settings-selectors';
import { POSTS_PER_PAGE } from '../../constants/pagination';
import NoPostsFiltered from '../no-posts-filtered';
import styles from './post-list-classic.scss';
import { CategorySelectHeadless } from '../category-select';
import withExperiment from '../../hoc/with-experiment';
import { CategoryListActions } from '../../containers/category-actions';
import MarkAllAsReadButton from '../mark-all-as-read-button';
import withAuth from '../../hoc/with-auth';

class PostListClassic extends Component {
  componentDidUpdate(prevProps) {
    const { page, entityCount, navigateWithinForum, buildPageUrl, category } = this.props;
    const lastPage = getLastPage(entityCount, POSTS_PER_PAGE);

    if (category._id !== prevProps.category._id) {
      return;
    }

    if (entityCount < prevProps.entityCount && page > lastPage) {
      navigateWithinForum(buildPageUrl(lastPage));
    }
  }

  renderPagination() {
    return (
      <div className={styles.paginationContainer}>
        <PaginationClassic
          page={this.props.page}
          entityCount={this.props.entityCount}
          showPerPage={POSTS_PER_PAGE}
          onChange={this.handlePageChange}
          buildPageUrl={this.props.buildPageUrl}
          changePageOnClick={false}
        />
      </div>
    );
  }

  renderCreateNewPostButton() {
    const { category, customCtaLabel, showCreatePostAction, postTypes } = this.props;
    return (
      showCreatePostAction && (
        <CreatePostButton
          categorySlug={category.slug}
          postTypes={postTypes}
          createPostCtaLabel={customCtaLabel}
        />
      )
    );
  }

  renderSortingSelect() {
    return (
      <div className={styles.selectContainer}>
        <SortingSelectCategoryPageDesktop />
      </div>
    );
  }

  renderFilterSelect() {
    return (
      <div className={styles.selectContainer}>
        <FilterSelectPostTypeDesktop />
      </div>
    );
  }

  renderCategorySelect() {
    return (
      <div className={styles.selectContainer}>
        <CategorySelectHeadless />
      </div>
    );
  }

  renderCategoryName() {
    const { category } = this.props;
    return <div className={styles.nameContainer}>{category.label}</div>;
  }

  renderControls({ top }) {
    const { category, showCategoryFilter, isForumTabsEnabled, isAuthenticated } = this.props;

    const isCategoryNameVisible =
      top && !showCategoryFilter && isForumTabsEnabled && !!category?._id;
    const isCategorySelectVisible = top && showCategoryFilter;
    const isSortingVisible = top;
    const isCategoryActionsVisible = !showCategoryFilter && isForumTabsEnabled && !!category?._id;

    return (
      <div
        className={classNames(styles.controlsContainer, styles.spacing)}
        data-hook="post-list-classic-controls"
      >
        {!top && this.renderPagination()}
        <div className={styles.controlsLeft}>
          {isCategoryNameVisible && this.renderCategoryName()}
          {isCategoryNameVisible && (isCategorySelectVisible || isSortingVisible) && (
            <VerticalSeparator className={styles.controlsSeparator} />
          )}
          {isCategorySelectVisible && this.renderCategorySelect()}
          {isSortingVisible && this.renderSortingSelect()}
        </div>
        <div className={styles.controlsRight}>
          {!isCategoryActionsVisible && isAuthenticated && (
            <MarkAllAsReadButton category={category} />
          )}
          {isCategoryActionsVisible && (
            <CategoryListActions showMarkPostsAsRead category={category} />
          )}
          {this.renderCreateNewPostButton()}
        </div>
      </div>
    );
  }

  handlePageChange = ({ page, buttonType }) => {
    this.props.changePage({
      page,
      meta: {
        bi: {
          buttonType,
        },
      },
    });
    this.props.onPageChange(page);
  };

  renderSeparator(key = 'separator', isFirstItem) {
    return (
      <tr key={key} aria-hidden="true">
        <td className={styles.separatorColumn} colSpan="100">
          <HorizontalSeparator className={isFirstItem && styles.topSeparator} />
        </td>
      </tr>
    );
  }

  renderEmptyState = () => {
    const { showMemberPosts, emptyStateFragment } = this.props;
    return (
      <React.Fragment>
        <HorizontalSeparator />
        {emptyStateFragment || <NoPostsFiltered noMemberPosts={showMemberPosts} />}
      </React.Fragment>
    );
  };

  renderContent() {
    const {
      posts,
      query,
      onLikeClick,
      isViewsCountEnabled,
      showCategoryLink,
      hasActiveFilter,
      emptyStateFragment,
    } = this.props;

    const shouldShowEmptyState = emptyStateFragment || (!posts.length && hasActiveFilter);
    return (
      <div className={styles.spacing} data-hook="post-list-classic">
        <table className={styles.table}>
          <PostListClassicHeader
            pagination={shouldShowEmptyState ? null : this.renderPagination()}
            isViewsCountEnabled={isViewsCountEnabled}
          />
          <tbody>
            {!shouldShowEmptyState &&
              posts.map((post, i) => [
                this.renderSeparator(`separator-${post?._id}`, i === 0),
                <PostListItemClassic
                  key={post._id}
                  post={post}
                  query={query}
                  onLikeClick={onLikeClick}
                  showCategoryLink={showCategoryLink}
                />,
              ])}
          </tbody>
        </table>
        {shouldShowEmptyState && this.renderEmptyState()}
      </div>
    );
  }

  renderLoader() {
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );
  }

  render() {
    const { isLoading, borderWidth, contentFontClassName, isLoaded, posts } = this.props;

    const className = classNames(
      styles.container,
      'forum-card-background-color',
      'forum-card-border-color',
      contentFontClassName,
    );
    const hasBottomControls = size(posts) >= POSTS_PER_PAGE / 3 && isLoaded;
    return (
      <div className={className} style={{ borderWidth }}>
        {isLoaded && this.renderControls({ top: true })}
        {isLoaded && <HorizontalSeparator />}
        {isLoading ? this.renderLoader() : this.renderContent()}
        {hasBottomControls && <HorizontalSeparator />}
        {hasBottomControls && this.renderControls({ top: false })}
      </div>
    );
  }
}

PostListClassic.propTypes = {
  onLikeClick: PropTypes.func.isRequired,
  category: PropTypes.object,
  posts: PropTypes.array,
  query: PropTypes.string,
  page: PropTypes.number,
  entityCount: PropTypes.number,
  borderWidth: PropTypes.number.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
  showCreatePostAction: PropTypes.bool,
  onPageChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isLoaded: PropTypes.bool,
  isViewsCountEnabled: PropTypes.bool,
  showCategoryLink: PropTypes.bool,
  buildPageUrl: PropTypes.func,
  hasActiveFilter: PropTypes.bool,
  navigateWithinForum: PropTypes.func,
  uniquePostTypesInAllCategories: PropTypes.array,
  showCategoryFilter: PropTypes.bool,
  showMemberPosts: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  customCtaLabel: PropTypes.string,
  postTypes: PropTypes.arrayOf(PropTypes.oneOf([QUESTION, DISCUSSION])),
  emptyStateFragment: PropTypes.node,
};

const mapRuntimeToPros = (state, ownProps, actions, host) => ({
  isViewsCountEnabled: getIsViewsCountEnabled(state, host.style),
  changePage: actions.changePage,
  navigateWithinForum: actions.navigateWithinForum,
});

export default flowRight(
  connect(mapRuntimeToPros),
  withCardBorderWidth,
  withFontClassName,
  withTranslate,
  withAuth,
  withExperiment({
    isForumTabsEnabled: EXPERIMENT_FORUM_TABS,
  }),
)(PostListClassic);
