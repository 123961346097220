import { values } from 'lodash';
import { PLUGINS } from '@wix/communities-forum-client-commons/dist/src/constants/plugins-constants';

export const MOBILE_SIDE_TOOLBAR_OFFSET = { x: 18, y: 0 };
export const SIDE_TOOLBAR_OFFSET = {
  desktop: { x: -43, y: 0 },
  mobile: {
    ios: MOBILE_SIDE_TOOLBAR_OFFSET,
    android: MOBILE_SIDE_TOOLBAR_OFFSET,
  },
};
export const CONTENT_COMMIT_DELAY = 150;

export const ALL_PLUGINS = values(PLUGINS);
export const DEFAULT_PLUGINS = [
  PLUGINS.LINK,
  PLUGINS.HASHTAG,
  PLUGINS.MENTIONS,
  PLUGINS.TEXT_COLOR,
  PLUGINS.LINK_PREVIEW,
  PLUGINS.TEXT_HIGHLIGHT,
  PLUGINS.SPOILER,
];
export const PLUGINS_WITH_BOTTOM_TOOLBAR = [
  PLUGINS.IMAGE,
  PLUGINS.VIDEO,
  PLUGINS.FILE_UPLOAD,
  PLUGINS.GIPHY,
  PLUGINS.EMOJI,
  PLUGINS.CODE_BLOCK,
  PLUGINS.COLLAPSIBLE_LIST,
  PLUGINS.DIVIDER,
  PLUGINS.HTML,
  PLUGINS.VERTICAL_EMBED,
];

export const DISABLED_PLUGINS_ON_MOBILE = [
  PLUGINS.EMOJI,
  PLUGINS.CODE_BLOCK,
  PLUGINS.DIVIDER,
  PLUGINS.HTML,
];
