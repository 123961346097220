import { flowRight, memoize, mapKeys, identity } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { WixComments } from '@wix/comments-ooi-client';
import { connect } from '../../../common/components/runtime-context';
import { OwnerSmallIcon } from '../../components/icons/owner-small-icon';
import withAuth from '../../hoc/with-auth';
import { pluginTypeMap } from './plugins';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { withRcePluginsConfig } from '../../hoc/with-rce-plugins-config';
import withCardBackgroundColor from '../../hoc/with-card-background-color';
import withButtonColor from '../../hoc/with-button-color';
import withSettingsColor from '../../hoc/with-settings-color';
import withExperiment from '../../hoc/with-experiment';
import { APP_TEXT_COLOR_PATH } from '@wix/communities-forum-client-commons/dist/src/constants/wix-params';
import { isSite } from '../../../common/store/basic-params/basic-params-selectors';
import { buildDeepLink } from '../../services/build-deep-comment-url';
import { getContextToken } from '../../services/context-token';
import withRelativeTimeFormatting from '../../hoc/with-relative-time-formatting';
import { buildCssOverride } from './css-override';
import { getCommentsPaginationConfig } from '../../selectors/comments-pagination-selectors';
import { isQuestion } from '@wix/communities-forum-client-commons/dist/src/constants/post-types';
import { getMarkedCommentLabel } from '../../services/get-marked-comment-label';
import { EXPERIMENT_USE_COMMENTING_TYPE_CLIENT } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import {
  getIsUploadLimiterEnabled,
  getUploadSizeLimit,
} from '../../selectors/app-settings-selectors';
import { VIDEO_UPLOAD_SIZE_LIMIT_MB } from '@wix/communities-forum-client-commons/dist/src/constants/media-limits';

const mapConfigToCommentsProps = (config, t) => {
  const typeLabel = getMarkedCommentLabel(config, t);

  return (
    typeLabel && {
      badgeLabel: typeLabel,
      markActionLabel: t('comment-marked-type.mark-as-type-label', { label: typeLabel }),
    }
  );
};

// https://wix.slack.com/archives/CAKBA7TDH/p1601553610052000
const memoizeFn = memoize.Cache === WeakMap ? identity : memoize;
class PostPageWixComments extends Component {
  state = { isInErrorState: false };
  componentDidCatch(error) {
    // internal comments error handler should catch errors, but there still can be cases where error bubbles up
    this.setState({ isInErrorState: true });
    console.error(error);
  }

  getCtxFields = memoizeFn(getContextToken);

  pluginConfig = mapKeys(this.props.editorPluginsConfig, (val, key) => pluginTypeMap[key]);

  formatRelativeTime = time => this.props.formatRelativeTime && this.props.formatRelativeTime(time);

  getPalette = () => {
    const { cardBackgroundColor, textColor, buttonColor } = this.props;
    return {
      bgColor: cardBackgroundColor,
      textColor,
      actionColor: buttonColor,
    };
  };

  ricosTheming = {
    theme: {
      palette: this.getPalette(),
    },
    cssOverride: buildCssOverride(this.props.style),
  };

  getDeepLinkProp = memoizeFn(
    (isLiveSite, generateLink) =>
      isLiveSite && {
        generateLink,
      },
  );

  getReactionsConfig = () => {
    if (this.props.isCommentingTypeEnabled && this.props.post.commentingType) {
      if (this.props.post.commentingType === 'vote') {
        return { commentReactions: { type: 'votes' } };
      } else {
        return { commentReactions: { type: 'likes' } };
      }
    }

    if (isQuestion(this.props.post.postType)) {
      return { commentReactions: { type: 'votes' } };
    } else {
      return { commentReactions: { type: 'likes' } };
    }
  };

  render() {
    const {
      post,
      t,
      isLiveSite,
      generateLink,
      hideHeaderDivider,
      loadEditorPluginsAsync,
      paginationConfig,
      markedCommentConfig,
      isOwner,
      isUploadLimiterEnabled,
      uploadSizeLimit,
    } = this.props;

    if (this.state.isInErrorState) {
      return null;
    }

    const fileUploadSizeLimit = isOwner
      ? undefined
      : isUploadLimiterEnabled
      ? uploadSizeLimit
      : VIDEO_UPLOAD_SIZE_LIMIT_MB;

    return (
      <WixComments
        markLabels={mapConfigToCommentsProps(markedCommentConfig, t)}
        hideZeroCommentsEmptyState
        pluginConfig={{
          loadEditorPluginsAsync,
          plugins: this.pluginConfig,
          type: 'async',
        }}
        fileUploadSizeLimit={fileUploadSizeLimit}
        resourceId={post._id}
        ctxFields={this.getCtxFields(post._id)}
        pagination={paginationConfig}
        formatRelativeTime={this.formatRelativeTime}
        isLocked={post.isCommentsDisabled}
        roleIcons={ROLE_ICONS}
        ricosTheming={this.ricosTheming}
        deepLink={this.getDeepLinkProp(isLiveSite, generateLink)}
        t={t}
        hideHeaderDivider={hideHeaderDivider}
        reactions={this.getReactionsConfig()}
      />
    );
  }
}

const ROLE_ICONS = {
  OWNER: OwnerSmallIcon,
  CONTRIBUTOR: OwnerSmallIcon,
};

PostPageWixComments.propTypes = {
  post: PropTypes.object,
  style: PropTypes.object,
  editorPluginsConfig: PropTypes.object.isRequired,
  isLiveSite: PropTypes.bool,
  generateLink: PropTypes.func,
  hideHeaderDivider: PropTypes.bool,
  paginationConfig: PropTypes.object,
  markedCommentConfig: PropTypes.object,
  isOwner: PropTypes.bool,
  isUploadLimiterEnabled: PropTypes.bool,
  uploadSizeLimit: PropTypes.number,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  style: host.style,
  generateLink: commentId => buildDeepLink(state, commentId, ownProps.post._id),
  isLiveSite: isSite(state),
  paginationConfig: getCommentsPaginationConfig(state, ownProps.post._id),
  isUploadLimiterEnabled: getIsUploadLimiterEnabled(state, host.style),
  uploadSizeLimit: getUploadSizeLimit(state, host.style),
});

export default flowRight(
  withRelativeTimeFormatting,
  withCardBackgroundColor,
  withButtonColor,
  withSettingsColor({
    path: APP_TEXT_COLOR_PATH,
    propName: 'textColor',
    siteColorFallback: 'color-5',
  }),
  withRcePluginsConfig,
  withAuth,
  withTranslate,
  withExperiment({
    isCommentingTypeEnabled: EXPERIMENT_USE_COMMENTING_TYPE_CLIENT,
  }),
  connect(mapRuntimeToProps),
)(PostPageWixComments);
