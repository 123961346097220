import { flowRight } from 'lodash';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import { MODAL_TYPE_DELETE_CATEGORY } from '../../components/modals/delete-category-modal/delete-category-modal-type';
import { MODAL_TYPE_UNFOLLOW_CATEGORY } from '../../components/modals/unfollow-category-modal/unfollow-category-modal-type';
import { DeleteIcon } from '../../components/icons/delete-icon';
import { EditIcon } from '../../components/icons/edit-icon';
import { GearIcon } from '../../components/icons/gear-icon';
import { getCategoryCount } from '../../../common/selectors/categories-selectors';
import withPermissions from '../../hoc/with-permissions';
import withTranslate from '../../../common/components/with-translate/with-translate';
import withAuth from '../../hoc/with-auth';
import { LockEmptyIcon } from '../../components/icons/lock-empty-icon';
import { NotificationIcon } from '../../components/icons/notification-icon';
import { NotificationFollowingIcon } from '../../components/icons/notification-following-icon';
import { CATEGORIES_MANAGER_EDIT_CATEGORY } from '../../actions/categories-manager-actions-constants';
import { THREE_DOT_ACTIONS } from '../../constants/bi-locations';
import ProfileActionsAddon from '../../components/profile-actions-addon/profile-actions-addon';
import styles from './category-actions.scss';
import { CheckMarkIcon } from '../../components/icons/check-mark-icon';
import withExperiment from '../../hoc/with-experiment';
import { EXPERIMENT_RECENT_ACTIVITY_CATS } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import * as SEARCH_PARAMS from '@wix/communities-forum-client-commons/dist/src/constants/url-search-params';
import { isFollowButtonVisible } from '../../services/is-follow-button-visible';
import { getMetaSiteId } from '../../../common/store/instance-values/instance-values-selectors';

export const withCategoryActions = WrappedComponent => {
  const WithCategoryActions = props => {
    const {
      can,
      hideFollowButton,
      category,
      forPublicUser,
      isRecentActivityCatsEnabled,
      showMarkPostsAsRead,
      isAuthenticated,
      metaSiteId,
      categoryId,
      showProfileActions,
      openModal,
      buttonClicked,
      setBiLocation,
      isOneCategoryRemaining,
      subscribe,
    } = props;
    const canSubscribeToCategory = can('subscribe', 'category', category);
    const canListCategory = can('list', 'category', category);
    const canEditCategory = can('edit', 'category');
    const canDeleteCategory = can('delete', 'category');

    const manageCategoriesUniversalLink = `https://manage.wix.app/forum/${metaSiteId}/manage-forum?s=true&d=`;
    // @TODO update to proper category edit screen link, when available in the app
    const editCategoryUniversalLink = `https://manage.wix.app/forum/${metaSiteId}/manage-forum?s=true&d=`;

    let manageCategoriesUrl = `https://www.wix.com/dashboard/${metaSiteId}/forum/categories`;
    let editCategoryUrl = `https://www.wix.com/dashboard/${metaSiteId}/forum/categories/${categoryId}`;

    manageCategoriesUrl = manageCategoriesUniversalLink + manageCategoriesUrl;
    editCategoryUrl = editCategoryUniversalLink + editCategoryUrl;

    const handleSubscribe = () => {
      buttonClicked({ action: 'follow_category', flag: 1 });

      return subscribe(categoryId);
    };

    const handleUnsubscribe = () => {
      buttonClicked({ action: 'unfollow_category', flag: 0 });

      openModal(MODAL_TYPE_UNFOLLOW_CATEGORY, { categoryId });
    };

    const followButtonAction = category.isSubscribed ? handleUnsubscribe : handleSubscribe;
    const isFollowBtnVisible =
      !hideFollowButton &&
      (canSubscribeToCategory || canListCategory) &&
      isFollowButtonVisible(category, canSubscribeToCategory);
    const followButton = isFollowBtnVisible
      ? {
          dataHook: 'category-actions__subscriptions',
          onClick: canSubscribeToCategory ? followButtonAction : forPublicUser(followButtonAction),
          icon: category.isSubscribed ? (
            <NotificationFollowingIcon
              strokeClass={styles.notificationsStroke}
              fillClass={styles.notificationsFill}
            />
          ) : (
            <NotificationIcon />
          ),
          label: category.isSubscribed
            ? 'category-actions.unsubscribe-category'
            : 'category-actions.subscribe-category',
          isAuthRequired: !canSubscribeToCategory,
        }
      : null;

    const markPostsAsRead = () => {
      props.markPostsAsRead(categoryId);
      props.buttonClicked({ action: 'mark_all_as_read' });
    };

    const isMarkPostsAsReadButtonVisible =
      isRecentActivityCatsEnabled && showMarkPostsAsRead && isAuthenticated;
    const markPostsAsReadButton = isMarkPostsAsReadButtonVisible
      ? {
          dataHook: 'category-actions__edit',
          onClick: markPostsAsRead,
          icon: <CheckMarkIcon className={styles.buttonIcon} />,
          label: 'recent-activity.mark-all-as-read',
        }
      : null;

    const handleEdit = () => {
      setBiLocation(THREE_DOT_ACTIONS, CATEGORIES_MANAGER_EDIT_CATEGORY);
      buttonClicked({ action: 'edit_category' });
    };

    const isEditButtonVisible = canEditCategory;
    const editButton = isEditButtonVisible
      ? {
          dataHook: 'category-actions__edit',
          onClick: handleEdit,
          to: editCategoryUrl,
          icon: <EditIcon />,
          label: 'category-actions.edit-category',
        }
      : null;

    const handleManageMembers = () => {
      setBiLocation(THREE_DOT_ACTIONS, CATEGORIES_MANAGER_EDIT_CATEGORY);
      buttonClicked({ action: 'manage_category_members' });
    };

    const isManageMembersButtonVisible = canEditCategory;
    const manageMembersButton = isManageMembersButtonVisible
      ? {
          dataHook: 'category-actions__manage-members',
          onClick: handleManageMembers,
          to: editCategoryUrl,
          queryParams: { [SEARCH_PARAMS.SCROLL_TO]: SEARCH_PARAMS.EDIT_PERMISSIONS_CARD },
          icon: <LockEmptyIcon />,
          label: 'category-actions.manage-category-members',
        }
      : null;

    const handleDelete = () => {
      buttonClicked({ action: 'delete_category' });
      openModal(MODAL_TYPE_DELETE_CATEGORY, { categoryId });
    };

    const isDeleteButtonVisible = canDeleteCategory && !isOneCategoryRemaining;
    const deleteButton = isDeleteButtonVisible
      ? {
          dataHook: 'category-actions__delete',
          onClick: handleDelete,
          icon: <DeleteIcon />,
          label: 'category-actions.delete-category',
        }
      : null;

    const handleManageCategories = () => {
      buttonClicked({ action: 'manage_category' });
    };

    const isManageCategoriesButtonVisible = canEditCategory;
    const manageCategoriesButton = isManageCategoriesButtonVisible
      ? {
          dataHook: 'category-actions__manage',
          onClick: handleManageCategories,
          to: manageCategoriesUrl,
          icon: <GearIcon />,
          label: 'breadcrumbs-actions.manage-categories',
        }
      : null;

    const profileActions = showProfileActions ? { component: <ProfileActionsAddon /> } : null;

    const shouldRenderDivider =
      (followButton ||
        markPostsAsReadButton ||
        editButton ||
        manageMembersButton ||
        deleteButton ||
        (showProfileActions && manageCategoriesButton)) &&
      (profileActions || (!showProfileActions && manageCategoriesButton));

    const actions = [
      followButton,
      markPostsAsReadButton,
      editButton,
      manageMembersButton,
      deleteButton,
      showProfileActions ? manageCategoriesButton : null,
      shouldRenderDivider ? { isDivider: true } : null,
      profileActions,
      showProfileActions ? null : manageCategoriesButton,
    ].filter(Boolean);

    return <WrappedComponent {...props} categoryActions={actions} />;
  };

  const mapRuntimeToProps = (state, { category }, actions) => {
    return {
      isOneCategoryRemaining: getCategoryCount(state) <= 1,
      subscribe: actions.subscribeToCategory,
      openModal: actions.openModal,
      setBiLocation: actions.setBiLocation,
      markPostsAsRead: actions.markPostsAsRead,
      metaSiteId: getMetaSiteId(state),
      categoryId: category._id,
      buttonClicked: data =>
        actions.buttonClicked({
          name: 'action_click',
          type: 'post',
          ...data,
          categoryId: category._id,
        }),
    };
  };

  return flowRight(
    connect(mapRuntimeToProps),
    withPermissions,
    withTranslate,
    withAuth,
    withExperiment({
      isRecentActivityCatsEnabled: EXPERIMENT_RECENT_ACTIVITY_CATS,
    }),
  )(WithCategoryActions);
};
