import { RootState } from '../../app/types/store-types';
import { ForumControllerActions } from '../../app/types/common-types';
import { useContextSelector } from './use-context-selector';
import AllStateCtx from '../components/runtime-context';
import { isShallowEqual } from '../components/runtime-context/is-shallow-equal';

type RuntimeMapper<T> = (state: RootState, actions: ForumControllerActions, host: any) => T;

/**
 * @internal
 * Optimized hook which will trigger rerender only if result is not shallowly equal.
 * Avoid creating new values in it. Don't:
 * const { myNevVal } = useApi(state => ( { nyNewVal: () => state.soemething }));
 */
export function useApi<T>(mapRuntimeToProps: RuntimeMapper<T>): T {
  return useContextSelector(
    AllStateCtx,
    ctx =>
      mapRuntimeToProps(ctx.state, (ctx.actions as unknown) as ForumControllerActions, ctx.host),
    isShallowEqual,
  );
}
