import { flowRight, isString, mapValues, noop } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import { WixRicosViewer, resolveFileUrl } from '@wix/ricos-viewer';
import { getIsPinterestPinEnabled } from '../../selectors/app-settings-selectors';
import { getLocation } from '../../../common/store/location/location-selectors';
import {
  isSeo,
  isEditor,
  getLanguage,
  isSSR,
  isPreview,
  getRicosBiParams,
  getInstance,
} from '../../../common/store/basic-params/basic-params-selectors';
import { PLUGINS } from '@wix/communities-forum-client-commons/dist/src/constants/plugins-constants';
import getImageUrl from '@wix/communities-forum-client-commons/dist/src/services/get-image-url';
import { isIos } from '../../../common/services/detect-platform';
import getOuterUrl from '../../services/get-outer-url';
import withDeviceType from '../../hoc/with-device-type';
import withFontClassName from '../../hoc/with-font-class-name';
import {
  createHashtagHref,
  createHashtagPath,
  MAX_PINTEREST_IMAGE_SIZE,
  HTML_IFRAME_SRC,
} from '../rich-content-editor/utils';
import { isMemberAreaInstalled } from '../../../common/store/communities-context/communities-context-selectors';
import MemberCardAsync from '../../containers/member-card-async';
import {
  viewerCustomStyleFn,
  styleSelectionPredicate,
  getTextColorSchema,
} from '../rich-content-editor/text-color-utils';
import { RCE_FILE_DOWNLOAD_WILL_BEGIN_SHORTLY } from '../messages/message-types';
import { mapConfigToPlugins } from './plugins';
import theme from '../rich-content-editor/theme';
import styles from './rich-content-viewer.scss';
import { withRcePluginsConfig } from '../../hoc/with-rce-plugins-config';
import withExperiment from '../../hoc/with-experiment';
import { EXPERIMENT_WIX_COMMENTS } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import withCardBackgroundColor from '../../hoc/with-card-background-color';
import withButtonColor from '../../hoc/with-button-color';
import withSettingsColor from '../../hoc/with-settings-color';
import { APP_TEXT_COLOR_PATH } from '@wix/communities-forum-client-commons/dist/src/constants/wix-params';
import withAuth from '../../hoc/with-auth';
import { keepFocus } from '../../services/keep-focus';
import { getMetaSiteId } from '../../../common/store/instance-values/instance-values-selectors';

class RichContentViewer extends Component {
  constructor(props) {
    super(props);
    this.initViewerProps();

    this.state = { mention: null };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.isEditor ||
      nextProps.pageUrl !== this.props.pageUrl ||
      nextProps.initialState !== this.props.initialState ||
      nextProps.isSSR !== this.props.isSSR ||
      nextProps.instance !== this.props.instance ||
      nextProps.isAuthenticated !== this.props.isAuthenticated ||
      this.state !== nextState
    );
  }

  componentDidUpdate() {
    if (this.props.isEditor) {
      this.initTheme();
    }
  }

  getImagePluginConfig() {
    const { isPinterestPinEnabled, isMobile } = this.props;
    const showPin = this.props.readOnly && isPinterestPinEnabled;
    return {
      imageProps: showPin
        ? src => ({
            'data-pin-url': this.props.pageUrl,
            'data-pin-media': isString(src)
              ? src
              : getImageUrl(src, MAX_PINTEREST_IMAGE_SIZE, MAX_PINTEREST_IMAGE_SIZE),
          })
        : { 'data-pin-nopin': true },
      disableExpand: isMobile,
    };
  }

  getVideoPluginConfig() {
    return {
      // Function is invoked when rendering video which has relative URL.
      // You should take the pathname and form a full URL.
      getVideoUrl: src => `https://video.wixstatic.com/${src.pathname}`,
    };
  }

  onMentionHover = (mention, ref) => {
    this.setState({ mention: { ...mention, ref } });
  };

  onHashTagClick = event => {
    event.preventDefault();
    event.stopPropagation();
    const pathname = createHashtagPath(event.target.innerText);
    this.props.navigateWithinForum(pathname);
  };

  validateUser = async ({ voteRole }) => {
    if (voteRole !== 'ALL' && !this.props.isAuthenticated) {
      return this.props.requestLogin();
    }
  };

  initViewerProps() {
    const {
      isMembersAreaInstalled,
      navigateToProfile,
      requestFileDownloadUrlPromisified,
      fileDownloadRequest,
      showMessage,
      style,
      origin,
      buttonClicked,
      sectionUrl,
      ricosBiParams,
      editorPluginsConfig,
      isWixCommentsEnabled,
      contentId,
      instance,
      metaSiteId,
    } = this.props;

    this.biSettings = {
      consumer: 'communities-forum-client',
      platform: 'Livesite',
      defaultParams: ricosBiParams,
      biCallbacks: {
        onViewerAction: (pluginId, actionName, value) => {
          switch (value) {
            case 'expand_image':
              return buttonClicked({ name: 'img_expand', origin });
            default:
              return;
          }
        },
      },
      contentId,
    };

    this.initTheme();

    const colorScheme = getTextColorSchema(style);

    this.config = isWixCommentsEnabled
      ? mapValues(editorPluginsConfig, config => config.viewerConfig || config.config)
      : {
          [PLUGINS.FILE_UPLOAD]: {
            resolveFileUrl: imgEntityData => {
              // This is needed for backwards compatibility.
              // Forum used to upload files via Node API service, which resulted in different entity structure
              // We are assuming that if entity has a 'path', it was uploaded via Node API, otherwise
              // It's assumed that RICOS upload logic was used.
              if (!imgEntityData.path) {
                return resolveFileUrl(metaSiteId, instance)(imgEntityData);
              }

              isIos() && showMessage(RCE_FILE_DOWNLOAD_WILL_BEGIN_SHORTLY);
              const getBiEventData = isSuccessful => ({
                fileId: imgEntityData.id,
                fileExtension: imgEntityData.type,
                mimeType: imgEntityData.mimeType,
                size: imgEntityData.size,
                isSuccessful,
                origin,
              });

              return requestFileDownloadUrlPromisified(imgEntityData.path)
                .then(data => {
                  fileDownloadRequest(getBiEventData(true));
                  return data.downloadUrl;
                })
                .catch(() => fileDownloadRequest(getBiEventData(false)));
            },
          },
          [PLUGINS.TEXT_COLOR]: {
            customStyleFn: viewerCustomStyleFn(colorScheme),
            styleSelectionPredicate: styleSelectionPredicate(colorScheme),
          },
          [PLUGINS.TEXT_HIGHLIGHT]: {
            customStyleFn: viewerCustomStyleFn(colorScheme, true),
            styleSelectionPredicate: styleSelectionPredicate(colorScheme),
          },
          [PLUGINS.HTML]: {
            htmlIframeSrc: HTML_IFRAME_SRC,
          },
          [PLUGINS.IMAGE]: this.getImagePluginConfig(),
          [PLUGINS.VIDEO]: this.getVideoPluginConfig(),
          [PLUGINS.MENTIONS]: {
            onMentionClick: isMembersAreaInstalled
              ? mention => navigateToProfile({ memberId: mention.id, memberSlug: mention.slug })
              : null,
            getMentionLink: noop,
            onMentionHover: this.onMentionHover,
          },
          [PLUGINS.HASHTAG]: {
            onClick: this.onHashTagClick,
            createHref: createHashtagHref(sectionUrl),
          },
          [PLUGINS.POLLS]: {
            onBeforeVote: this.validateUser,
          },
        };

    this.plugins = mapConfigToPlugins(this.config);
  }

  initTheme() {
    const {
      style,
      titleFontClassName,
      contentFontClassName,
      compact,
      isSeo,
      themeGetter,
      type,
      isMobile,
    } = this.props;

    this.theme = themeGetter({
      style,
      readOnly: true,
      compact,
      isViewer: true,
      isSeo,
      titleFontClassName,
      contentFontClassName,
      type,
      isMobile,
    });
  }

  wrapWithMemberCard(component) {
    const { mention } = this.state;
    const { isMembersAreaInstalled } = this.props;

    const hasMention = mention !== null;

    return isMembersAreaInstalled && hasMention ? (
      <MemberCardAsync
        key={mention.id} // to remount the card and prevent showing old data in the card, while loading new
        customTargetRef={mention.ref}
        viewedMemberId={mention.id}
        viewedMemberSlug={mention.slug}
      >
        {component}
      </MemberCardAsync>
    ) : (
      component
    );
  }

  render() {
    const {
      initialState,
      isMobile,
      locale,
      isSeo,
      experiments,
      cardBackgroundColor,
      textColor,
      buttonColor,
      instance,
    } = this.props;

    return this.wrapWithMemberCard(
      <div className={styles.container}>
        <WixRicosViewer
          wixExperiments={experiments}
          content={initialState}
          plugins={this.plugins}
          isMobile={isMobile}
          locale={locale}
          cssOverride={this.theme}
          biSettings={this.biSettings}
          seoSettings={isSeo}
          instance={instance}
          linkSettings={{
            anchorTarget: '_blank',
            rel: { nofollow: true, sponsored: false, ugc: true },
          }}
          theme={{
            palette: {
              bgColor: cardBackgroundColor,
              textColor,
              actionColor: buttonColor,
            },
          }}
        />
      </div>,
    );
  }
}

RichContentViewer.propTypes = {
  initialState: PropTypes.object,
  compact: PropTypes.bool,
  style: PropTypes.object.isRequired,
  navigateWithinForum: PropTypes.func.isRequired,
  pageUrl: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  titleFontClassName: PropTypes.string,
  contentFontClassName: PropTypes.string,
  isSeo: PropTypes.bool,
  themeGetter: PropTypes.func,
  navigateToProfile: PropTypes.func,
  isMembersAreaInstalled: PropTypes.bool,
  isEditor: PropTypes.bool,
  isSocialSharingLinksEnabled: PropTypes.bool,
  sectionUrl: PropTypes.string,
  origin: PropTypes.string,
  requestFileDownloadUrlPromisified: PropTypes.func,
  showMessage: PropTypes.func,
  fileDownloadRequest: PropTypes.func,
  type: PropTypes.string,
  locale: PropTypes.string,
  isSSR: PropTypes.bool,
  isPreview: PropTypes.bool,
  buttonClicked: PropTypes.func,
  ricosBiParams: PropTypes.object,
  contentId: PropTypes.string,
  instance: PropTypes.string,
};

RichContentViewer.defaultProps = {
  compact: false,
  themeGetter: theme,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => {
  const location = getLocation(state);
  const sectionUrl = location.sectionUrl;
  return {
    style: host.style,
    pageUrl: getOuterUrl(location.pathname, sectionUrl),
    isSeo: isSeo(state),
    isEditor: isEditor(state),
    isPreview: isPreview(state),
    isMembersAreaInstalled: isMemberAreaInstalled(state),
    isPinterestPinEnabled: getIsPinterestPinEnabled(state, host.style),
    sectionUrl,
    navigateWithinForum: actions.navigateWithinForum,
    navigateToProfile: actions.navigateToProfile,
    requestFileDownloadUrlPromisified: actions.requestFileDownloadUrlPromisified,
    showMessage: actions.showMessage,
    fileDownloadRequest: actions.fileDownloadRequest,
    locale: getLanguage(state),
    isSSR: isSSR(state),
    buttonClicked: actions.buttonClicked,
    experiments: state.experiments,
    ricosBiParams: getRicosBiParams(state),
    instance: getInstance(state),
    metaSiteId: getMetaSiteId(state),
    requestLogin: keepFocus(actions.requestLoginPromisified),
  };
};

export default flowRight(
  withRcePluginsConfig,
  withExperiment({
    isWixCommentsEnabled: EXPERIMENT_WIX_COMMENTS,
  }),
  withCardBackgroundColor,
  withButtonColor,
  withSettingsColor({
    path: APP_TEXT_COLOR_PATH,
    propName: 'textColor',
    siteColorFallback: 'color-5',
  }),
  connect(mapRuntimeToProps),
  withDeviceType,
  withFontClassName,
  withAuth,
)(RichContentViewer);
