import { flowRight } from 'lodash';
import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { DISCUSSION } from '@wix/communities-forum-client-commons/dist/src/constants/post-types';
import Link from '../link/internal-link';
import IconTooltip from '../icon-tooltip';
import { CommentIconSmall } from '../icons/comment-icon-small';
import withTranslate from '../../../common/components/with-translate/with-translate';
import ProtectedButton from '../../containers/protected-button';
import LikeButton from '../like-button';
import withAuth from '../../hoc/with-auth';
import { LIKE_POST } from '../../constants/interactions';
import PostListItemRecentActivity from '../post-list-item-recent-activity';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';
import { connect } from '../../../common/components/runtime-context';
import { getCategory } from '../../../common/selectors/categories-selectors';
import { CommentDisabledIcon } from '../icons/comment-disabled-icon';
import styles from './post-list-card-footer.scss';
import CounterNumber from '../counter-number';
import { getEntityLikeCount } from '../../selectors/counters-selectors';

class PostListCardFooter extends Component {
  handleLikeClick = e => {
    const { userEventsClickLike, interactionStarted, incrementPostLikeCount, post } = this.props;
    userEventsClickLike({
      type: 'post',
      _id: post._id,
      isLiked: post.isLiked,
    });
    e.preventDefault();
    interactionStarted(LIKE_POST);
    incrementPostLikeCount(post._id);
  };

  render() {
    const { post, category, forPublicUser, likeCount, t } = this.props;

    const isDiscussion = post.postType === DISCUSSION;

    const postLink = `/${category?.slug}/${post?.slug}`;

    return (
      <div className={styles.footer}>
        <div className={styles.footerLeftSide}>
          <ProtectedButton
            onClick={forPublicUser(this.handleLikeClick, { preventDefault: true })}
            data-hook="post-list-item__like-button"
            actionDetails={{ action: LIKE_POST, args: [post._id] }}
          >
            <LikeButton
              isLiked={post.isLiked}
              likeCount={likeCount}
              isDisabled={post.isLikeInProgress}
            />
          </ProtectedButton>
          <span
            className={classNames(styles.likesCount, 'forum-text-color')}
            data-hook="post-list-item__like-count"
          >
            <CounterNumber
              tKey="post-list-item.like-count"
              initialValue={likeCount}
              entityId={post._id}
              likeCount
            />
          </span>
          <Link
            className={classNames(
              styles.commentsCountContainer,
              'forum-text-color',
              'forum-link-hover-color',
              'button-hover-fill',
              'forum-icon-fill',
            )}
            to={postLink}
            data-hook="post-list-item__comment-count"
          >
            {post.isCommentsDisabled ? (
              <IconTooltip text={t('post-list-item.post-closed')} className={styles.tooltip}>
                <CommentDisabledIcon />
              </IconTooltip>
            ) : (
              <CommentIconSmall
                title={t(
                  isDiscussion ? 'post-list-item.total-comments' : 'post-list-item.total-answers',
                  {
                    count: post.totalComments || 0,
                  },
                )}
              />
            )}
            <span className={classNames(styles.commentsCount)}>
              <CounterNumber initialValue={post.totalComments} entityId={post._id} totalComments />
            </span>
          </Link>
        </div>

        <PostListItemRecentActivity
          post={post}
          category={category}
          withDirectionIcon
          withText
          isAvatarSmall
        />
      </div>
    );
  }
}

const mapRuntimeToProps = (state, ownProps, actions) => ({
  interactionStarted: actions.interactionStarted,
  userEventsClickLike: actions.userEventsClickLike,
  incrementPostLikeCount: actions.incrementPostLikeCount,
  category: getCategory(state, ownProps.post.categoryId),
  likeCount: getEntityLikeCount(state, ownProps.post),
});

PostListCardFooter.propTypes = {
  post: PropTypes.object.isRequired,
};

export default flowRight(
  withTranslate,
  withAuth,
  connect(mapRuntimeToProps, [REDUCERS.CATEGORIES, REDUCERS.POSTS]),
)(PostListCardFooter);
